import { BodyText, Heading, TextSize } from 'components/atoms/typography';

import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import { LinkButton } from 'components/atoms/button';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import PageContentLayout from 'components/layouts/PageContentLayout';
import React from 'react';
import { useRoutePath } from 'state/context/nav';

export { Head } from 'components/atoms/Head';

export type Props = AppPageProps;

const Page404: React.FC<Props> = ({ pageContext }) => {
  const homeRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.HOME);

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <Container
        narrow
        className="flex min-h-rhythm12 flex-col items-center justify-center"
      >
        <Heading level={2} size={TextSize.Trafalgar}>
          Oops!
        </Heading>
        <Heading level={1} size={TextSize.DoublePica}>
          Page Not Found
        </Heading>
        <BodyText>It seems we can't find what you're looking for.</BodyText>
        <LinkButton className="md:w-1/3" to={homeRoute.path}>
          Go back to Home
        </LinkButton>
      </Container>
    </PageContentLayout>
  );
};

export default Page404;
